@font-face {
  font-family: "Nichrome";
  src: url("./Nichrome0.5-Regular.woff2") format("woff2"),
    url("./Nichrome0.5-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Nichrome";
  src: url("./Nichrome0.5-Dark.woff2") format("woff2"),
    url("./Nichrome0.5-Dark.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Nichrome";
  src: url("./Nichrome0.5-Bold.woff2") format("woff2"),
    url("./Nichrome0.5-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Nichrome";
  src: url("./Nichrome0.5-Black.woff2") format("woff2"),
    url("./Nichrome0.5-Black.woff") format("woff");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Nichrome";
  src: url("./Nichrome0.5-Ultra.woff2") format("woff2"),
    url("./Nichrome0.5-Ultra.woff") format("woff");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
