.card {
  background: transparent;
  background-size: cover;
  background-position: center;
  border: 1px solid get-color("caution");
  box-sizing: border-box;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.9);
  border-radius: 16px 0px 16px 8px;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  h3 {
    color: #fff;
    min-height: 192px;
    text-shadow: rgba(0, 0, 0, 0.6) 1px 2px 0;
  }
  .card__image {
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      filter: contrast(1.25) saturate(1.5) brightness(80%);

      &.source {
        opacity: 0;
        transition: opacity 1s ease-in;
      }
      &.loaded {
        opacity: 1;
      }
    }
  }

  .card__tags {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 2.65rem;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -0.5rem;
      right: -1rem;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        270deg,
        get-color("caution") 50%,
        rgba(0, 0, 0, 0) 90%
      );
      filter: drop-shadow(0px 0px 10px #ebce13);
      margin: 0 0 1rem;
    }
  }
  .card__actions {
    display: none; // Make accessible
    &:after {
      transition: all 25s ease-in-out;
    }
  }
  &:hover {
    .card__actions {
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:after {
        opacity: 0;
        animation: show 0.15s cubic-bezier(0, 1.05, 1, 0.5) forwards;
        content: "";
        background: rgba(0, 0, 0, 0.8);
        mix-blend-mode: soft-light;
        backdrop-filter: blur(6px);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tag {
  background: get-color("caution");
  border-radius: 4px;
  color: get-color("dark");
  padding: 6px 12px;
  margin: 0.5rem 0.5rem 0 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  white-space: nowrap;
}

.tag--hyper {
  background: get-color("hyper");
}
.tag--tron {
  background: get-color("tron");
}
