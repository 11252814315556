// First up: config
@import "config";

// Next: pull in gorko for design tokens
@import "../../../../node_modules/gorko/gorko.scss";

// Pull in modern reset
@import "reset";

// Global CSS starts
body {
  background: get-color("dark");
  color: get-color("pinky");
  line-height: 1.5;
  overflow-x: hidden;
  letter-spacing: 1px;
  padding-bottom: 8rem;
}

@media (min-width: 45em) {
  body {
    padding-bottom: 0;
  }
}

img {
  display: block;
}

h1,
h2,
h3 {
  line-height: 1.2;
}

h1 {
  font-size: get-size("700");
}
h2 {
  font-size: get-size("600");
}

h3 {
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 0 0.5rem;
}

a {
  color: currentColor;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

:focus {
  outline: none;
}

// :focus {
//   outline: 2px dotted;
//   outline-offset: 0.25rem;
// }

// Utilities
@import "utilities/flow";
@import "utilities/radius";
@import "utilities/splitter";
@import "utilities/wrapper";
@import "utilities/visually-hidden";

//Blocks

@import "blocks/card";
@import "blocks/date-picker";

.container {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}
.grid {
  display: grid;
  grid-template-columns: [a] 1fr [b] 1fr [c] repeat(3, [c] 30vw) [c] 1fr [b] 1fr [a];
}

@media (min-width: 45em) {
  .grid {
    grid-template-columns: [a] 1fr [b] 1fr [c] repeat(3, [c] 20vw) [c] 1fr [b] 1fr [a];
  }
}

@media (min-width: 64em) {
  .grid {
    grid-template-columns: [a] 1fr [b] 1fr [c] repeat(3, [c] 15vw) [c] 1fr [b] 1fr [a];
  }
}
.grid > :not([class*="grid"]) {
  grid-column: c / c 4;
}
.grid--partial {
  grid-column: b / b 2;
}
.grid--full {
  grid-column: a / a 2;
}
.grid--cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-auto-rows: 1fr;
  row-gap: 1rem;
}
@media (min-width: 50em) {
  .grid--cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 80em) {
  .grid--cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Layout */

main {
  padding: 0 1rem;
}
.u-flex {
  display: flex;
}
.u-flex-row {
  flex-direction: row;
}
.u-flex-col {
  flex-direction: column;
}
.u-flex-justify-content-center {
  justify-content: center;
}

.u-text-left {
  text-align: left;
}
.u-text-center {
  text-align: center;
}
.u-text-right {
  text-align: right;
}
.u-text-normal {
  font-weight: 400;
}
.u-text-bold {
  font-weight: 700;
}

.u-space-auto {
  margin: 0 auto;
}
.u-space-top-sm {
  margin-top: 1rem;
}
.u-space-top-md {
  margin-top: 2rem;
}
.u-space-top-lg {
  margin-top: 3rem;
}
.u-space-top-xl {
  margin-top: 4rem;
}
.u-space-right-sm {
  margin-right: 1rem;
}
.u-space-right-md {
  margin-right: 2rem;
}
.u-space-right-lg {
  margin-right: 3rem;
}
.u-space-right-xl {
  margin-right: 4rem;
}
.u-space-bottom-sm {
  margin-bottom: 1rem;
}
.u-space-bottom-md {
  margin-bottom: 2rem;
}
.u-space-bottom-lg {
  margin-bottom: 3rem;
}
.u-space-bottom-xl {
  margin-bottom: 4rem;
}
.u-space-left-sm {
  margin-left: 1rem;
}
.u-space-left-md {
  margin-left: 2rem;
}
.u-space-left-lg {
  margin-left: 3rem;
}
.u-space-left-xl {
  margin-left: 4rem;
}
.button {
  display: inline-block;
  background: get-color("turq");
  border: 1px solid get-color("turq");
  box-sizing: border-box;
  border-radius: 4px;
  color: get-color("dark");
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  border: 0;
  outline: none;
  vertical-align: middle;
  -webkit-appearance: none;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 6px;
    transform: translateY(-2px);
    text-decoration: none;
  }
}

.button--link {
  background: transparent;
  color: #2bd9ce;

  &:hover {
    background: transparent;
    box-shadow: none;
    text-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px;
  }
}

.button--icon {
  background: transparent;
  color: #2bd9ce;
  font-size: 0;

  &:hover {
    background: transparent;
    box-shadow: none;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
  }
}
.button--secondary {
  background: get-color("caution");
  border: 1px solid get-color("caution");
  color: get-color("currant");
}
.button--tertiary {
  background: get-color("haze");
  border: 1px solid get-color("haze");
  color: get-color("pinky");
}
.button--destructive {
  background: get-color("fire");
  border: 1px solid get-color("fire");
  color: get-color("light");
}

.button-set {
  display: flex;
  gap: 1rem;
}

form > div {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
}

label {
  display: block;
  font-weight: 700;
}
input[type="text"],
input[type="email"],
input[type="password"] {
  background: get-color("currant");
  border: 1px solid get-color("lightning");
  box-sizing: border-box;
  border-radius: 4px;
  color: get-color("pinky");
  padding: 0.5rem;
  width: 100%;
  flex: 1 1 auto;
  outline: none;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  background: get-color("haze");
}

select {
  background: get-color("currant")
    url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTg5NzUgOS43MDE5TDEyLjAwMDIgMTUuNzEyM0wxOC4wMTA2IDkuNzAxOUwxNi41OTYzIDguMjg3NjlMMTIuMDAwMiAxMi44ODM5TDcuNDAzOTYgOC4yODc2OUw1Ljk4OTc1IDkuNzAxOVoiIGZpbGw9IiNGQUQ1RUUiLz4KPC9zdmc+Cg==")
    center right no-repeat;
  border: 1px solid get-color("lightning");
  border-radius: 4px;
  color: get-color("pinky");
  padding: 0.5rem;
  -webkit-appearance: none;
}

.site-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: get-color("currant");
  padding: 0.5rem 1rem;
}

@media (min-width: 45em) {
  .site-main {
    flex-direction: row;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  background: #1b0426;
  border: get-color("caution") 1px solid;
  border-radius: 10px;
  box-shadow: get-color("caution") 0 0 12px;
  width: 32rem;
  padding: 1rem;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sub-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 5rem;
  & > * + * {
    margin-top: 1rem;
  }
}
@media (min-width: 45em) {
  .sub-header {
    flex-direction: row;
    & > * + * {
      margin-top: 0;
    }
  }
}

.profile {
  display: none;
}
@media (min-width: 64em) {
  .profile {
    display: inline-block;
  }
}

nav {
  a {
    display: inline-block;
    font-weight: 700;
    padding: 0.5rem 1rem;
    color: get-color("caution");
    border-radius: 4px 0 4px 2px;
    margin: 0 0.5rem -0 0;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    &:hover,
    &.active {
      background: get-color("caution");
      color: get-color("dark");
    }
  }
}

.button-group {
  .button {
    background: get-color("lightning");
    border-radius: 0;
    color: get-color("pinky");
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    &.active {
      background: get-color("haze");
    }
  }
}

.multi-select > div > div {
  border: 0;
  padding: 0;
}
input[type="text"].searchBox {
  background: get-color("currant");
  border: 1px solid get-color("lightning");
  &::placeholder {
    color: get-color("pinky");
  }
}
span.chip {
  background: get-color("caution");
  color: get-color("dark");
  border-radius: 2px;
  font-weight: 700;
}
ul.optionContainer {
  background: #1b0426;
  border: get-color("caution") 1px solid;
  & > li:hover {
    background: get-color("turq");
  }
  & > .highlight {
    background: get-color("hyper");
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  .spin {
    animation: spin 1.5s linear infinite;
    transform-origin: center;
  }
  .loader__text {
    text-transform: uppercase;
    font-weight: 600;
    margin: 0.5rem;
    color: get-color("turq");
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-toast-notifications__container {
  font-family: Nichrome, Helvetica, Arial, sans-serif;
  align-items: center;
}

.game-cover {
  min-height: 4rem;
  h1 {
    color: get-color("caution");
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shadow: get-color("hyper") 2px 2px 0;
  }
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    mix-blend-mode: luminosity;
    filter: grayscale(1) brightness(0.5);
    border-radius: 9px;
  }
}

.site-main__profile {
  display: flex;
  align-items: center;
}

.player-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 4px 0 4px 2px;
  border: get-color("caution") 1px solid;
  margin: 1rem 0;
}

.checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  color: var(--color);

  &--disabled {
    color: var(--disabled);
  }
}

.checkbox__control {
  display: inline-grid;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  background-color: get-color("currant");
  border: 1px solid get-color("lightning");

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
}

.checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1rem;
    height: 1rem;

    &:focus + .checkbox__control {
      box-shadow: 0 0 0 0.05em get-color("hyper");
    }

    &:checked + .checkbox__control svg {
      transform: scale(1);
    }

    &:disabled + .checkbox__control {
      color: var(--disabled);
    }
  }
}
.anticpated {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  svg {
    filter: drop-shadow(1px 1px 0 get-color("dark"));
  }
}

.sidebar > div:first-child {
  display: none;
}

@media (min-width: 45em) {
  .sidebar {
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 1rem;
  }
  .sidebar > div:first-child {
    display: block;
  }
}

.link-button {
  display: inline;
  padding: 0;
  border: 0;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  color: currentColor;
  -webkit-appearance: none;
}

.category__item {
  background: #11021a;
  border: 1px solid get-color("caution");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0 0 1rem;
  a {
    text-decoration: none;
    &:hover {
      transform: translateY(-2px);
    }
  }
}

.tabber {
  background: get-color("caution");
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: 1rem;
}

.tabber__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.tabber__list a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: get-color("hyper");
  font-size: get-size("300");
  text-align: center;
  &.active {
    background: linear-gradient(360deg, #6b0f99 0%, #ebce13 98%);
    color: get-color("pinky");
  }
}
.tabber__list svg {
  display: block;
  margin: 0 auto;
}

@media (min-width: 45em) {
  .tabber {
    display: none;
  }
}

.categories {
  > li {
    margin: 0 0 0.5rem;
    a {
      display: block;
      padding: 0.5rem;
      background: #11021a;
      border-radius: 4px;
      border: 1px solid #11021a;
      text-decoration: none;
      &:hover,
      &:active {
        background: get-color("haze");
        border-color: get-color("caution");
      }
    }
  }
}

.main-navigation {
  display: none;
}
@media (min-width: 45em) {
  .main-navigation {
    display: block;
  }
}
